import React from "react"
import PropTypes from "prop-types"
import "twin.macro"

const ErrorMessage = ({ message = "Oops, something went wrong..." }) => {
  return (
    <>
      {message && (
        <div tw="mb-2 max-w-xl lg:mb-8">
          <div tw="w-full text-base text-red-600">{message}</div>
        </div>
      )}
    </>
  )
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default ErrorMessage
