import React from "react"
import { Link, graphql } from "gatsby"
import "twin.macro"
import PropTypes from "prop-types"

const Breadcrumbs = ({ items }) => {
  // Create a new array with modified breadcrumbs
  const modifiedItems = items.reduce((acc, item, index) => {
    // Add the current item
    acc.push(item);
    
    // If it's the first item (Startseite) and URL contains /rueckblicke
    if (index === 0 && items.some(item => item.url?.includes('/rueckblicke'))) {
      // Add Veranstaltungen and Rückblicke after Startseite
      acc.push(
        { text: "Veranstaltungen", url: "" },
        { text: "Rückblicke", url: "" }
      );
    }
    return acc;
  }, []);

  return (
    <nav aria-label="Breadcrumb" tw="flex text-sm">
      <ol tw="inline-flex flex-wrap items-start">
        {modifiedItems.map((item, index) => (
          <li key={`breadcrumb-${index}`}>
            {/* if page is not published if there is `?page_id=` in the url */}
            {item?.url && item.url !== "" && !item.url.includes("page_id") && ((index + 1) < modifiedItems.length) ? (
              <Link to={item.url} tw="text-link-negative font-bold">{item.text}</Link>
            ) : (
              <span>{item.text}</span>
            )}
            {index < modifiedItems.length - 1 && <span tw="mx-1">{"/"}</span>}
          </li>
        ))}
      </ol>
    </nav>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Breadcrumbs

export const pageQuery = graphql`
  fragment BreadcrumbsFragment on WpPage {
    seo {
      breadcrumbs {
        text
        url
      }
    }
  }
  fragment JobBreadcrumbsFragment on WpJob {
    seo {
      breadcrumbs {
        text
        url
      }
    }
  }
  fragment PostBreadcrumbsFragment on WpPost {
    seo {
      breadcrumbs {
        text
        url
      }
    }
  }
  fragment ReviewBreadcrumbsFragment on WpRueckblick {
    seo {
      breadcrumbs {
        text
        url
      }
    }
  }
`
