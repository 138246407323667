import { Global } from "@emotion/react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { React, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { TabProvider } from "../contexts/tab-context"
import { useForm } from "react-hook-form"
import axios from "axios"
import Breadcrumbs from "../components/breadcrumbs"
import ClipLoader from "react-spinners/ClipLoader"
import Container from "../components/container"
import ErrorMessage from "../components/form/error-message"
import Header from "../components/navigation"
import Icon from "../components/icon"
import IconFooter from "../../static/videos/Illu-Final-Footer.svg"
import Instagram from "../components/social/instagram"
import LinkedIn from "../components/social/linkedin"
import ScrollTop from "../components/scroll-top-button"
import tw, { css, GlobalStyles } from "twin.macro"

const Layout = props => {

  const { isHomePage, children } = props;
  const path = props.path;
  const breadcrumbs = props.data?.breadcrumbs?.seo?.breadcrumbs || [];

  const { register, handleSubmit, watch, formState: { errors, isSubmitting }, } = useForm({
    defaultValues: {
      email: "",
      data_storage: false,
    },
  });

  const submitForm = async (data) => {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    try {
      const response = await axios.post(
        `/api/newsletter`,
        JSON.stringify(data),
        options
      )
      setIsSuccessfullySubmitted(response.status === 200)
    } catch (e) {
      if (e.response.status >= 400) {
        if (e.response.status === 403) {
          setDuplicateUserError(true)
          return
        }
        setSubmitError(true)
      }
    }
  }

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [duplicateUserError, setDuplicateUserError] = useState(false);

  const footerMenu = [
    {
      id: "qwe2121",
      label: "Presse",
      url: "/presse/",
    },
    {
      id: "qwe1232",
      label: "Stellenangebote",
      url: "/stiftung/stellenangebote/",
    },
    {
      id: "qwe1233",
      label: "Team",
      url: "/stiftung/team/",
    },
  ]

  const {
    mainMenu: { nodes: mainMenuItems },
    footerMenu: { nodes: footernMenuItems },
    legalMenu: { nodes: legalMenuItems },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      mainMenu: allWpMenuItem(
        filter: { locations: { eq: PRIMARY }, parentId: { eq: null } },
        sort: {fields: order, order: ASC}
      ) {
        nodes {
          id
          label
          uri
          childItems {
            nodes {
              id
              label
              uri
            }
          }
        }
      }
      footerMenu: allWpMenuItem(filter: { locations: { eq: FOOTER } }) {
        nodes {
          id
          path
          label
          url
        }
      }
      legalMenu: allWpMenuItem(filter: { locations: { eq: LEGAL } }) {
        nodes {
          id
          label
          uri
        }
      }
    }
  `)

  const fallbackSmWidth = 767
  const fallbackSmHeight = 1156
  const fallbackLgWidth = 2110
  const fallbackLgHeight = 878

  return (
    <div>
      <GlobalStyles />
      <Global
        styles={css`
          body {
            ${tw`antialiased font-regular`}
            color: #25303B;
          }
          html {
            scroll-padding-top: 96px;
            @media (prefers-reduced-motion: no-preference) {
              scroll-behavior: smooth;
            }
            @media (min-width: 1024px) {
              scroll-padding-top: 172px;
            }
            @media (min-width: 1240px) {
              scroll-padding-top: 124px;
            }
            /*
            @media (min-width: 1240px) {
              font-size: calc(12.25px + 0.3vw);
              -webkit-marquee-increment: 0vw;
            }
            @media (min-width: 1920px) {
              font-size: 18.007px;
            }
            */
          }
          p.small {
            font-size: 80%;
          }
        `}
      />
      <div
        tw="relative flex min-h-screen flex-col items-stretch"
        className="global-wrapper"
        data-is-root-path={isHomePage}
      >
        <Header
          mainMenuItems={mainMenuItems}
          footernMenuItems={footernMenuItems}
        />
        <main tw="mt-28 flex-grow pb-14 md:pb-18 lg:mt-48 lg:pb-20" role="main">
          {breadcrumbs && breadcrumbs.length > 1 && (
            <Container>
              <div tw="my-10">
                <Breadcrumbs items={breadcrumbs} />
              </div>
            </Container>
          )}
          {children}
          <ScrollTop />
        </main>

        {path === '/' && (
          <div tw="bg-[#4C8092] flex items-center justify-center">
            <Icon svg={IconFooter} tw="fill-current" />
          </div>
        )}

        <footer tw="flex-shrink-0" role="contentinfo">
          <div tw="bg-sand-500 py-10 md:py-16">
            <Container>
              <div tw="grid grid-cols-12 gap-6 py-4 md:lg:py-14 lg:py-18">

              {!isSuccessfullySubmitted && (
                <div tw="col-span-12 md:col-span-6 md:place-self-start lg:col-span-6">

                  <h1 tw="text-4xl font-bold pb-8">Informationen aus der Bundesstiftung</h1>

                  <form onSubmit={handleSubmit(submitForm)}>
                    <div tw="w-full">
                      <div tw="pr-3">
                        <input
                          {...register("email", {
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            required: true,
                          })}
                          css={[
                            tw`mb-4 mr-3 w-64 border-2 border-transparent px-2 md:px-3 pt-2.5 pb-2.5 text-base outline-none placeholder:text-sm placeholder:text-anthracite-500 invalid:border-magenta-500 focus:border-anthracite-500 focus:ring-transparent disabled:bg-sand-200`,
                            isHomePage ? tw`bg-sand-500` : tw`bg-white`,
                          ]}
                          type="email"
                          placeholder="Ihre E-Mail Adresse*"
                        />

                        <button type="submit" disabled={isSubmitting}
                          tw="h-12 inline-block bg-lilac-500 px-7 py-2 font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                        >ANMELDEN
                          {isSubmitting && (
                            <span tw="px-2">
                              <ClipLoader color={"currentColor"} size={20} />
                            </span>
                          )}
                        </button>

                        {submitError ? (
                          <ErrorMessage
                            message={
                              "Leider gab es einen Fehler. Bitte versuchen Sie es noch einmal oder kontaktieren Sie uns unter: "
                            }
                          >
                            <a
                              href="mailto:gleichstellungstag@bundesstiftung-gleichstellung.de"
                              tw="text-base"
                            >
                              gleichstellungstag@bundesstiftung-gleichstellung.de
                            </a>
                          </ErrorMessage>
                        ) : null}
                        {duplicateUserError ? (
                          <ErrorMessage message="Die*der Nutzer*in mit dieser E-Mail-Adresse wurde bereits registriert." />
                        ) : null}

                      </div>

                      {errors?.email && errors?.email.type === "required" && (
                        <div tw="top-0 left-0 text-sm text-red-500">
                          Dies ist ein Pflichtfeld
                        </div>
                      )}
                      {errors?.email && errors?.email.type === "pattern" && (
                        <div tw="top-0 left-0 text-sm text-red-500">
                          Dieses Feld muss eine gültige E-Mail-Adresse enthalten
                        </div>
                      )}

                      <div tw=" pt-4">
                        <label tw="flex cursor-pointer items-center">
                          <input
                            tw="h-5 w-5 flex-shrink-0 cursor-pointer appearance-none border border-anthracite-500 bg-white bg-contain bg-center bg-no-repeat align-top text-lilac-500 transition duration-200 checked:border-lilac-500 checked:bg-lilac-500 focus:outline-none focus:ring-2 focus:ring-lilac-500 dark:border-gray-600 dark:bg-white dark:ring-offset-gray-800 dark:focus:ring-lilac-500"
                            type="checkbox"
                            {...register("data_storage", { required: true, })}
                          />
                          <span tw="ml-2 flex cursor-pointer flex-wrap text-sm md:ml-3">
                            Ich habe die Datenschutzerklärung gelesen und erkenne diese an.*
                          </span>
                        </label>

                        {errors?.data_storage && (
                        <div tw="top-0 left-0 text-sm text-red-500">
                          Dies ist ein Pflichtfeld
                        </div>
                        )}

                        <div tw="top-0 left-0 mt-5 text-sm">
                        *Pflichtfelder
                        </div>
                      </div>

                    </div>

                  </form>
                </div>
                )}
                {isSuccessfullySubmitted && (
                  <div tw="p-15 col-span-12 md:col-span-6 md:place-self-start lg:col-span-6">
                    <span>
                      Vielen Dank für Ihre Anmeldung. Zur Bestätigung Ihrer Anmeldung erhalten Sie eine E-Mail.
                    </span>
                  </div>
                )}
                <div tw="col-span-12 md:col-span-3 lg:col-span-3">
                  <div tw="w-full pb-4 md:pb-4 text-xl">
                    <h1 tw="font-bold pb-8">Kontakt</h1>
                    Bundesstiftung Gleichstellung
                    <br />
                    Karl-Liebknecht-Str. 34
                    <br />
                    10178 Berlin
                  </div>
                  <div tw="w-full text-xl">
                    <a href="tel:+4930994057000" tw="underline">
                      +49 30-9940570-00
                    </a>
                    <br />
                    <a
                      href="mailto:info@bundesstiftung-gleichstellung.de"
                      tw="underline"
                    >
                      info@bundesstiftung-gleichstellung.de
                    </a>
                  </div>
                </div>
                <div tw="col-span-12 md:col-span-3 lg:col-span-3">
                  <h1 tw="text-xl font-bold pb-8">Links</h1>
                  <ul tw="justify-start">
                    {footerMenu.map(({ label, url, id }) => (
                      <li tw="pb-2 text-xl" key={`nav-${id}`}>
                        <Link
                          to={url || `/`}
                          tw="text-xl px-0.5 py-3 navLink-animated underline-lillac"
                        >
                          {label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div tw="grid grid-cols-12 gap-6 py-4 md:lg:py-14 lg:py-18">
                <div tw="col-span-12 md:col-span-6 md:place-self-start lg:col-span-6">
                  <div tw="gap-2 w-full flex">
                    <div tw="font-bold flex flex-row items-center">
                      <Instagram />
                    </div>
                    <div tw="font-bold flex flex-row items-center">
                      <LinkedIn />
                    </div>
                  </div>
                </div>
                <div tw="col-span-12 md:col-span-6 md:place-self-start lg:col-span-6">
                  <ul tw="flex justify-start flex-col md:flex-row">
                    {legalMenuItems.map(({ label, uri, id }) => (
                      <li tw="pr-8 text-xl inline pb-2 md:pb-0" key={`nav-${id}`}>
                        <Link
                          to={uri || `/`}
                          tw="text-xl px-0.5 py-3 navLink-animated underline-lillac"
                        >
                          {label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

            </Container>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Layout
